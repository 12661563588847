
/* Dependencies */
import { defineComponent } from 'vue';

/**
 * Header component
 */
export default defineComponent({
  props: {
    partnerId: {
      type: String,
      required: false,
    },
  },
});
